button.button {
  background-repeat: no-repeat;
  background-size: 50px;
  background-position: center;
  padding: 30px;
  background-color: transparent;
  border: 0;
}

.trophy {
  display: inline;
  padding: 0;
  margin-bottom: -1px;
  margin-right: 10px;
  border: 0;
  height: 1em;
}

button.button:hover {
  cursor: pointer;
}

button.button:focus {
  outline: 0;
}

.glitchImage {
  margin-bottom: 2em;
}

.formField {
  margin-bottom: 1em;
}
